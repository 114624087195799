<template>
    <div class="list-placeholder">
        <div v-for="(_, index) in items" :key="index"
            :class="{
                ['placeholder-' + animation]: true,
                'mb-3': items.length > 1
            }">
            <div class="placeholder bg-primary rounded w-100"
                :class="{ 
                    ['py-' + size]: true,
                }"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        count: {
            type: Number,
            default: 3
        },
        animation: {
            type: String,
            default: 'glow',
            validator (value: string) {
                return ['glow', 'wave'].includes(value)
            }
        },
        size: {
            type: Number,
            default: 5
        }
    },
    computed: {
        items (): Array<number> {
            return new Array(this.count).fill(0)
        }
    }
})
</script>